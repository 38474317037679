/* Features / Services section */
#features {
    .section {
        overflow: hidden;
        position: relative;
        @media (min-width: 1200px) {
            height: 450px;
        }
        @media (max-width: 1199px) {
            height: 355px;
        }    
        @media (max-width: 991px) {
            height: auto;
        }
        padding-bottom: 0;
        .big-title {
            color: @primary;
        }
        .col-md-5,
        .col-lg-4 {
            @media (min-width: 1200px) {
                height: 450px;
                margin-top: -50px;
            }
            @media (max-width: 1199px) {
                .centerY {
                    top: auto;
                    .transform(translate(0));
                }
                height: 355px;
            }
            @media (max-width: 991px) {
                height: auto;
            }
        }
        .col-lg-8,
        .col-md-7 {
            img {
                margin-top: 25px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
} 