form {
    padding: 20px 0;
    fieldset {
        div.field {
            label {
                font-weight: normal; 
                color: #bdbdbd;
            }
            div.middleColumn {
                input, select, textarea {
                    border-radius: 0;
                    border: 0;
                    border-bottom: 1px solid #CCC;

                    width: 100%;
                    padding: 5px;
                    margin-bottom: 10px; 

                    &:focus {
                        outline: none;
                        border-bottom: 1px solid #3898EC;
                    }
                }
                input {

                }
                select {

                }
                textarea {

                }
            }
        }
    }
    div.Actions {
        input[type="submit"] {
            &:extend(.btn);
            &:extend(.btn-xlg);
            background-color: @theme-secondary;
            color: #FFF;

            margin-bottom: 15px;
        }
    }
}

// MailChimp Overrides
//---------------------------

#mc_embed_signup {
  background: transparent !important;
  #mc_embed_signup_scroll {
    input.email {
      width: 100%;
      float: left;
      @media (min-width: @screen-xs-min) {
        width: 190px !important;
      }
      @media (min-width: @screen-lg-min) {
        width: 250px !important;
      }
    }
  }
  input.button {
    width: 100%;
    float: left;
    margin-left: 0;
    @media (min-width: @screen-xs-min) {
      width: 170px;
      margin-left: 5px;
    }
    @media (min-width: @screen-md-min) {
      width: 100%;
    }
  }
}