.md-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 800px;
    min-width: 100%;
    height: 100%;
    z-index: 999999 + 1;
    visibility: hidden;
    .backface-visibility(hidden);
    .md-body {
        padding: 80px 0;
        max-width: 800px;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
    }
}
#Grid .md-body {
	@media (min-width: 768px) {
		img {
			max-width: 500px;
			max-height: 295px; 
		}
	}
}
.md-show {
    visibility: visible;
     ~ .md-overlay {
        opacity: 1;
        visibility: visible;
     }
}
.md-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: 2000;
    opacity: 0;
    background: #fff;
    .transition(all 0.3s ease);
}

/* Content styles */

.md-content {
	text-align: left;
    background: #fff;
    margin: 0 auto;
    overflow-y: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1999;
    color: @grey600;
    .title {
        font-size: 45px;
    }
    .text {
        font-size: 17.5px;
        line-height: 30px;
        font-weight: 300;
    }
}

.icon-close {
    width: 50px;
    height: 50px;
    position: absolute;
    overflow: hidden;
    z-index: 100;
    cursor: pointer;
    top: 35px;
    right: 35px;
    &:after {
        .transform(rotate(45deg));
    }
    &:before {
        .transform(rotate(-45deg));
    }
    &:before,
    &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        width: 1px;
        height: 100%;
        background: @grey600;
    }
}

/*=== Animation for modal windows ===*/

/* Scale + rotateY (only for screen larger than 992px) */
.md-effect-1 {
    @media (min-width: 992px) {
        .md-content {
            .transform(perspective(1500px) rotateY(-90deg) scale(0.5));
            .transition(all 0.3s ease-in-out);
            .transform-style(preserve-3d);
            .transform-origin(50% 50%);
            overflow: hidden;
            opacity: 0;
        }
        &.md-show .md-content {
            .transform(perspective(1500px) rotateY(0deg) scale(1));
             opacity: 1;
             overflow: auto;
        }
    }
    @media (max-width: 992px) {
        /* */
        .md-content {
            .transform(scale(0.5));
            .transition(all 0.3s ease-in-out);
             opacity: 0;
        }
        &.md-show .md-content {
            .transform(scale(1));
            opacity: 1;
        }
    }
}

/* Simple scale */
.md-effect-2 {
    .md-content {
        .transform(scale(0.25));
        .transition(all 0.25s ease-in-out);
         opacity: 0;
    }
    &.md-show .md-content {
        .transform(scale(1));
        opacity: 1;
    }
}