/*
 * ===================================
 * Collapse
 * 
 * includes: accordion (using on the Support page)
 * ===================================
 * */

.panel-group {
    .panel {
        margin-bottom: -6px;
        &:not(:first-child),
        &:not(:last-child) {
            border-radius: 0;
        }
        &:first-child .panel-heading {
            .border-radius-top(2px);
        }
        &:last-child .panel-heading {
            .border-radius-bottom(2px);
        }
    }
    .panel-body { 
        color: @grey600;
    }
    .panel-heading {
        border-radius: 0;
        padding: 15px 25px;
        .transition(background 0.35s ease-out);
        &:hover {
            background-color: @grey100;
        }
    }
    a {
        .panel-heading {
            border-bottom: 1px solid @grey200;
            margin-bottom: -1px;
        }
        .panel-title,
        .panel-heading {
            color: @grey600;
            font-size: 20px;
            line-height: 1.1;
        }
    }
    .panel-title > i {
        color: @primary;
        margin-right: 10px;
    }
}