.footer {
  color: @footer-text;
  .container {

    .footer__col {
      color: @footer-text;
      a {
        color: @footer-text;
        &:hover, &:focus, &:active {
          color: darken(@footer-text, 10%);
        }
      }
      .footer__header {
        margin-bottom: 20px;
        font-size: 20px;
        text-transform: uppercase;
        color: @footer-text;
      }
      .footer__content {
        list-style: none;
        padding-left: 0;
        > li {
          > a {
            display: block;
            padding: 2.5px 0;
            color: @footer-text;
            &:hover, &:focus, &:active {
              color: darken(@footer-text, 10%);
            }
          }
        }
      }
    }
    .footer__hr {
      border-top: 0.5px solid @footer-text;
    }
  }
}