body {
  font-family: 'Raleway', sans-serif;
  h1, h2, h3, h4, h5, h6 {
    color: @font-primary;
    font-family: @font-family;
  }
}

.text-block, .profile-circle > p {
  color: @font-secondary;
}

.cbp-l-grid-work-title {
  color: @font-primary !important;
}

a {
  color: @font-tertiary;
}
