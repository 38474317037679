.btn-light {
  background-color: @theme-primary;
  color: @theme-tertiary;
    &:hover, &:focus, &:active {
    background-color: darken(@theme-primary, 5%);
    border-color: darken(@theme-primary, 5%);
    color: @theme-tertiary;
  }
}

.btn-dark {
  background-color: #f1f1f1;
  color: #444444;
   &:hover, &:focus, &:active {
    background-color: darken(#f1f1f1, 5%);
    border-color: darken(#f1f1f1, 5%);
    color: #444444;
  }
}

.btn-brand {
  background-color: @theme-tertiary;
  color: @theme-primary;
    &:hover, &:focus, &:active {
    background-color: darken(@theme-tertiary, 5%);
    border-color: darken(@theme-tertiary, 5%);
    color: @theme-primary;
  }
}