// transition 
.transition(@transition) {
    // -webkit-transition: @transition;
    transition: @transition;
}

.transition(@transition, @transition) {
    // -webkit-transition: @transition, @transition;
    transition: @transition, @transition;
}

.transition(@transition, @transition, @transition, @transition, @transition) {
    // -webkit-transition: @transition, @transition, @transition, @transition, @transition;
    transition: @transition, @transition, @transition, @transition, @transition;
}

.transition-webkit(@transition) {
    // -webkit-transition: -webkit-@transition;
    transition: @transition;
}
.transition-webkit(@transition, @transition) {
    // -webkit-transition: -webkit-@transition, @transition;
    transition: @transition, @transition;
}

.transition-delay(@delay) {
    // -webkit-transition-delay: @delay;
    transition-delay: @delay;
}

.transition-duration(@duration) {
    // -webkit-transition-duration: @duration;
    transition-duration: @duration;
}

.animation-direction(@direction) {
	// -webkit-animation-direction: @direction;
	animation-direction: @direction;
}

// transform 
.transform(@transform) {
    // -webkit-transform: @transform;
    // -moz-transform: @transform;
    // -ms-transform: @transform;
    transform: @transform;
}

.transform(@transform, @transform) {
    // -webkit-transform: @transform, @transform;
    // -moz-transform: @transform, @transform;
    // -ms-transform: @transform, @transform;
    transform: @transform, @transform;
}

.transform(@transform, @transform, @transform) {
    // -webkit-transform: @transform, @transform, @transform;
    // -moz-transform: @transform, @transform, @transform;
    // -ms-transform: @transform, @transform, @transform;
    transform: @transform, @transform, @transform;
}

.transform(@transform, @transform, @transform, @transform) {
    // -webkit-transform: @transform, @transform, @transform, @transform;
    // -moz-transform: @transform, @transform, @transform, @transform;
    // -ms-transform: @transform, @transform, @transform, @transform;
    transform: @transform, @transform, @transform, @transform;
}

.transform-style(@transform-style) {
    transform-style: @transform-style;
    // -webkit-transform-style: @transform-style;
    // -moz-transform-style: @transform-style;
}

.perspective(@perspective) {
    perspective: @perspective;
    // -webkit-perspective: @perspective;
    // -moz-perspective: @perspective;
}

.transform-origin(@origin) {
    // -webkit-transform-origin: @origin;
    // -moz-transform-origin: @origin;
    // -o-transform-origin: @origin;
    // -ms-transform-origin: @origin;
    transform-origin: @origin;
}

// user select 
.user-select(@user-select) {
    // -webkit-touch-callout: @user-select;
    // -webkit-user-select: @user-select;
    // -khtml-user-select:  @user-select;
    // -moz-user-select: @user-select;
    // -ms-user-select: @user-select;
    user-select: @user-select;
}

// box-sizing 
.box-sizing(@box-sizing) {
     // -moz-box-sizing: @box-sizing;
    box-sizing: @box-sizing;
}

// backfacevisibility 
.backface-visibility(@backface-visibility) {
    // -webkit-backface-visibility: @backface-visibility;
    backface-visibility: @backface-visibility;
}

//background size
.background-size(@background-size) {
    background-size: @background-size;
    // -webkit-background-size: @background-size;
}

// animations 
.animation-name(@animation-name) {
    // -webkit-animation-name: @animation-name;
    animation-name: @animation-name;
}
.animation-name(@animation-name, @transition) {
    // -webkit-animation-name: @animation-name;
    animation-name: @animation-name;
    // -webkit-transition: @transition;
    transition: @transition;
}
.animation(@animation) {
     // -webkit-animation: @animation;
     animation: @animation;
}

.animation-duration(@animation-duration) {
    animation-duration: @animation-duration;
    // -webkit-animation-duration: @animation-duration;
}

// box shadow 
.box-shadow(@box-shadow) {
     // -moz-box-shadow: @box-shadow;
     // -webkit-box-shadow: @box-shadow;
    box-shadow: @box-shadow;
}
.box-shadow(@box-shadow, @box-shadow) {
    //  -moz-box-shadow: @box-shadow, @box-shadow;
    // -webkit-box-shadow: @box-shadow;
    box-shadow: @box-shadow, @box-shadow;
}