.hover(@color, @background: false, @start-transparent: false) {
  transition: color ease 500ms, background-color ease 500ms;
  & when (@start-transparent = false) {
    color: @color;
    background: @background;
  }
  &:hover, &:active, &:focus {
    & when (@background = false) {
      color: darken(@color, 15%);
    }
    & when not (@background = false) {
      color: @color;
      & when (@start-transparent = false) {
        background-color: darken(@background, 15%)
      }
      & when not (@start-transparent = false) {
        background: @background;
      }
    }
  }
}