/*  ---------------------------------------------------------------
    Override src url to match rest of project
    --------------------------------------------------------------- */

@font-face {
  font-family: 'revicons';
  src: url('../fonts/revicons.eot?5510888');
  src: url('../fonts/revicons.eot?5510888#iefix') format('embedded-opentype'),
  url('../fonts/revicons.woff?5510888') format('woff'),
  url('../fonts/revicons.ttf?5510888') format('truetype'),
  url('../fonts/revicons.svg?5510888#revicons') format('svg');
  font-weight: normal;
  font-style: normal;
}