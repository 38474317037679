/*
 * ===================================
 * Forms
 * 
 * includes: Form with Icon, Transparent Form, Sign Up/In Pages
 * ===================================
 * */

// Fix missing var, this colors form placeholders
@hintText: #CCC;

@media (max-width: 767px) {
	.form-horizontal {
		margin-left: 15px;
		margin-right: 30px;
	}
}
.form-control {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.42857143;
    color: @gray-light;
    background-color: #fff;
    background-image: none;
    border: none;
	border-radius: 0;
    border-bottom: 1px solid @grey300;
    box-shadow: none;
    .transition(all 0.25s ease-in-out);
    &:focus {
        outline: 0;
        border-bottom-color: @primary;
		box-shadow: none;
    }
	&::-webkit-input-placeholder { /* WebKit browsers */
        color: @hintText;
}
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
       color: @hintText;
       opacity:  1;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
       color: @hintText;
       opacity:  1;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10+ */
       color: @hintText;
    }
}

/* Icon in form field */
.icon {
    @size: 50px;
    @font-size-text: 16px;
    @line-height-text: @font-size-text + 2;
    @p-vertical: (@size - @font-size-text) / 2;
    @height: @line-height-text + (2 * @p-vertical);
    .btn {
    min-height: @size;
    }
    .form-control {
        height: @height;
        padding: @p-vertical 15px @p-vertical @size;
        font-size: @font-size-text;
    }
    .control {
        position: relative;
        i {
            position: absolute;
            font-size: 150%;
            color: @grey400;
            top: 0;
            z-index: 2;
            .transition(all 0.35s ease-in-out);
            width: @size;
            height: @size;
            line-height: @size;
            text-align: center;
            left: 0;
        }
    }
	textarea.form-control {
		line-height: normal !important;
		height: auto !important;
	}
	
	/* validation */
	label[class^="error"] {
		color: @danger;
		float: right;
		font-weight: 400;
		opacity: 0.65;
		position: absolute;
		top: 50%;
		right: 0;
		z-index: 1;
		.transform(translateY(-50%));
		margin-bottom: 0;
		@media (max-width: 767px) {
			right: 20px;	
		}
//		&:before {
//			content: "*";
//			padding-right: 3px;
//		}
	}
	#Message-error {
		top: 30px;
	}
}

/*=== Easy Form Styles ===*/

#error,
#success {
	display: none;
	color: #FFF;
	padding: 20px;
	margin-bottom: 20px;
	font-weight: 300;
	font-size: 16px;
	border-radius: @border-radius;
}
#error {
	background: @danger;
}
#success{
	background: @success;
}
#easy {
	#vimage {
		width: 84px;
		height: 52px;
	}
}