.light {
  background-color: @theme-tertiary;
    h1, h2, h3, h4, h5, h6, p {
  color: @theme-primary;
  }
}

.dark {
  background-color: #444;
  h1, h2, h3, h4, h5, h6, p {
     color: #f1f1f1;
  }
}

.brand {
  background-color: @theme-primary;
    h1, h2, h3, h4, h5, h6, p {
  color: @theme-tertiary;
  }
}