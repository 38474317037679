// Border radius top
.border-radius-top(@border-radius-top) {
    border-top-left-radius: @border-radius-top;
    border-top-right-radius: @border-radius-top;
}

// Border radius bottom
.border-radius-bottom(@border-radius-bottom) {
    border-bottom-left-radius: @border-radius-bottom;
    border-bottom-right-radius: @border-radius-bottom;
}

// Border radius left 
.border-radius-left(@border-radius-left) {
    border-top-left-radius: @border-radius-left;
    border-bottom-left-radius: @border-radius-left;
}

// Border radius right 
.border-radius-right(@border-radius-right) {
    border-top-right-radius: @border-radius-right;
    border-bottom-right-radius: @border-radius-right;
}