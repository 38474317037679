.navbar {
  .navbar-brand {
    img {
      transition: all ease 500ms;
      margin-top: -15px;
      max-height: 50px;
    }
  }
  li.dropdown.section {
      padding: 0;
  }
}